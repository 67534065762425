<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        重點科別培育計畫服務單位及學校設置
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="類別">
          <b-select
            :options="[
              { value: 1, text: '培育學校' },
              { value: 2, text: '服務單位' },
            ]"
            v-model="searchType"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="啟用狀態">
          <b-select
            :options="[
              {
                value: null,
                text: '全部',
              },
              {
                value: true,
                text: '啟用',
              },
              {
                value: false,
                text: '不啟用',
              },
            ]"
            v-model="searchIsActive"
            @change="filterItems"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="單位代碼">
          <b-input v-model="searchCode" @keydown.enter="filterItems"></b-input>
        </b-input-group>
        <b-input-group prepend="單位名稱">
          <b-input
            :list="searchType == 1 ? 'my-list-id' : 'my-list-id2'"
            v-model="searchPlace"
            @keydown.enter="filterItems"
          ></b-input>
          <datalist id="my-list-id" v-show="searchType == 1">
            <option
              v-for="(item, i) in schoolNameOptions"
              :key="`schoolNameOptions${i}`"
              >{{ item }}</option
            >
          </datalist>
          <datalist id="my-list-id2" v-show="searchType == 2">
            <option
              v-for="(item, i) in unitNameOptions"
              :key="`unitNameOptions${i}`"
              >{{ item }}</option
            >
          </datalist>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div>
        <b-check
          switch
          v-model="item.isActive"
          @change="saveIsActiveData(item)"
        >
          啟用
        </b-check>
      </div>

      <div :title="item.type">
        {{ item.type || "" }}
      </div>
      <div :title="item.MId">
        {{ item.MId || "" }}
      </div>
      <div :title="item.MedicalName">
        {{ item.MedicalName || "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const headersContent = [
  { name: "啟用狀態", key: "isActive", sortDesc: null },
  { name: "類別", key: "", sortDesc: null },
  { name: "單位代碼", key: "MId", sortDesc: null },
  { name: "單位名稱", key: "MedicalName", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],
      allItems: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {},
      modalType: 1,

      //search
      searchType: 1,
      searchPlace: "",
      searchCode: "",
      searchIsActive: null,

      //searchOptions or ModalOptions
      schoolNameOptions: [],
      unitNameOptions: [],
    };
  },
  components: {
    Treeselect,
  },
  computed: {},

  methods: {
    clearSearch() {
      this.searchPlace = "";
      this.searchCode = "";
      this.searchIsActive = null;
      this.currentPageNum = 1;
      this.pagingRowPerPage = 20;
      this.headersContent.forEach((s) => {
        s["sortDesc"] = null;
      });
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      let sortBy = this.orderBy.map((s) => ({
        prop: s.includes("desc") ? s.split(" ")[0] : s,
        direction: s.includes("desc") ? 1 : -1,
      }));

      this.allItems.sort(function(a, b) {
        let i = 0,
          result = 0;
        while (i < sortBy.length && result === 0) {
          result =
            sortBy[i].direction *
            (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString()
              ? -1
              : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString()
              ? 1
              : 0);
          i++;
        }
        return result;
      });
      this.filterItems();
    },
    async saveIsActiveData(item) {
      let url = "";
      if (this.searchType == 1) {
        url = item.isActive
          ? "MedicalInstitutions/AddSchool"
          : "MedicalInstitutions/RemoveSchool";
      } else if (this.searchType == 2) {
        url = item.isActive
          ? "MedicalInstitutions/AddHospital"
          : "MedicalInstitutions/RemoveHospital";
      }

      const obj = {
        MId: item.MId,
        Category: item.Category,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      let apiUrl = "";

      try {
        if (this.searchType == 1) {
          apiUrl = "MedicalInstitutions/GetSchools";
        } else if (this.searchType == 2) {
          apiUrl = "MedicalInstitutions/GetHospitals";
        }
        let { List, Include } = await window.axios.get(`${apiUrl}?category=T`);
        let arr = List.map((s) => {
          s.type = this.searchType == 1 ? "培育學校" : "服務單位";
          s.isActive = Include.find((k) => k == s.MId) ? true : false;
          return s;
        });

        this.allItems = JSON.parse(JSON.stringify(arr));
        this.items = JSON.parse(JSON.stringify(arr));
        this.filterItems();
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    filterItems() {
      //top & skip
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;

      //search Filter
      let arr = JSON.parse(JSON.stringify(this.allItems));
      if (this.searchPlace) {
        arr = arr.filter((s) => s.MedicalName.includes(this.searchPlace));
      }
      if (this.searchCode) {
        arr = arr.filter((s) => s.MId.includes(this.searchCode));
      }
      if (typeof this.searchIsActive == "boolean") {
        arr = arr.filter((s) => s.isActive == this.searchIsActive);
      }

      this.rowCount = arr.length;
      this.totalCountStr = `共${arr.length} 筆`;

      //pageNow
      arr = arr.slice(skip, top + skip);
      this.items = JSON.parse(JSON.stringify(arr));
    },
    async getSearchOptions() {
      const { List } = await window.axios.get(
        "MedicalInstitutions/GetSchools?category=T"
      );
      const arr = List.map((s) => s.MedicalName);
      this.schoolNameOptions = this.$dedup(arr);

      const res2 = await window.axios.get(
        "MedicalInstitutions/GetHospitals?category=T"
      );
      const arr2 = res2.List.map((s) => s.MedicalName);
      this.unitNameOptions = this.$dedup(arr2);
    },
  },
  async mounted() {
    await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.filterItems();
    },
    pagingRowPerPage(v) {
      this.filterItems();
    },
    searchType(v) {
      this.headersContent[0].sortDesc = null;
      this.searchPlace = "";
      this.searchCode = "";
      this.searchIsActive = null;
      this.currentPageNum = 1;
      this.pagingRowPerPage = 20;
      this.headersContent.forEach((s) => {
        s["sortDesc"] = null;
      });
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 260px 330px 330px 448px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 38px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: max-content max-content 260px 400px repeat(
      3,
      max-content
    );
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
</style>
