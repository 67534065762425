<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        學校系別管理
      </h5>

      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="系組名稱">
          <b-input v-model="searchMajor" @keydown.enter="getData"></b-input>
        </b-input-group>

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <!-- <b-button variant="warning" @click="openModal(1)"
          >新增學校科系</b-button
        > -->
      </div>
    </section>
    <header class="dtc-grid-header ml-1 my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>

    <table
      class="ml-1"
      v-for="(schoolItem, schoolIndex) in items"
      :key="`outer${schoolIndex}`"
    >
      <tr v-for="(item, i) in schoolItem.array" :key="`insider${i}`">
        <td style="width:120px" class="table-content">
          <b-button
            variant="success"
            class="mr-2"
            size="sm"
            @click="openModal(2, item)"
            >編輯</b-button
          >
          <!-- <b-button variant="danger" size="sm" @click="deleteMajor(item)"
            >刪除</b-button
          > -->
        </td>
        <td style="width:140px">
          <b-form-checkbox
            v-model="item.IsActive"
            switch
            @change="saveMajorIsActiveData(item)"
          >
            {{ item.IsActive ? "啟用" : "不啟用" }}
          </b-form-checkbox>
        </td>
        <td rowspan="0" style="width:170px" v-if="i == 0">
          {{ item.SchoolId || "" }}
        </td>
        <td rowspan="0" style="width:180px" v-if="i == 0">
          {{ item.SchoolName || "" }}
        </td>
        <td rowspan="0" style="width:170px" v-if="i == 0">
          {{ item.SchoolCode || "" }}
        </td>
        <td style="width:170px">{{ item.majorIdChinese || "" }}</td>
        <td style="width:170px">{{ item.MajorId || "" }}</td>
        <td style="width:170px">
          {{ item.MajorName || "" }}
        </td>
        <td style="width:170px">
          {{ item.MajorYear || "" }}
        </td>
      </tr>
    </table>

    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '編輯'}學校科系`"
        size="md"
        header-bg-variant="warning"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="學校名稱" class="mt-2">
            <b-input
              v-model="modalSchoolName"
              class="edit-content"
              disabled
              v-if="modalType == 2"
            ></b-input>
            <b-select
              v-if="modalType == 1"
              :options="schoolObjectForModal"
              v-model="modalSchoolName"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="學校代碼" class="mt-2">
            <b-input
              v-model="modalSchoolCode"
              class="edit-content"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="學校編號" class="mt-2">
            <b-input
              v-model="modalSchoolId"
              class="edit-content"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="系組別" class="mt-2">
            <b-select
              :options="majorObject"
              v-model="modalMajorIdChinese"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="系組代碼" class="mt-2">
            <b-input
              v-model="modalMajorId"
              class="edit-content"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="系組名稱" class="mt-2">
            <b-input v-model="modalMajorName" class="edit-content"></b-input>
          </b-input-group>
          <b-input-group prepend="學年制" class="mt-2">
            <vue-numeric
              v-model="modalMajorYear"
              style="width: 341px;text-align: end;padding-right:5px"
              :max="9"
              class="edit-content"
            ></vue-numeric>
          </b-input-group>
          <b-input-group prepend="啟用狀態" class="mt-2">
            <b-select
              :options="[
                { value: true, text: '啟用' },
                { value: false, text: '不啟用' },
              ]"
              v-model="modalIsActive"
            ></b-select>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveMajorData"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
const year = new Date().getFullYear() - 1905;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
import buildQuery from "odata-query";

const headersOne = [
  { name: "操作", key: "", sortDesc: null },
  { name: "啟用狀態", key: "", sortDesc: null },
  { name: "學校編號", key: "", sortDesc: null },
  { name: "學校名稱", key: "", sortDesc: null },
  { name: "學校代碼", key: "", sortDesc: null },
  { name: "系組別", key: "MajorId", sortDesc: null },
  { name: "系組代碼", key: "MajorId", sortDesc: null },
  { name: "系組名稱", key: "MajorName", sortDesc: null },
  { name: "學年制", key: "MajorYear", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "ShowTable",
  data() {
    return {
      headersOne,
      items: [],

      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      years,
      searchSchool: null,
      searchMajor: "",

      schoolObject: [],
      majorObject: [],
      schoolObjectForModal: [],

      selectedYear: new Date().getFullYear() - 1911,
      modalType: 1,

      modalSchoolCode: "",
      modalSchoolName: "",
      modalSchoolId: "",
      modalMajorIdChinese: "",
      modalMajorId: "",
      modalMajorName: "",
      modalMajorYear: "",
      modalIsActive: false,
    };
  },
  components: {},
  props: [],
  computed: {},
  methods: {
    async deleteMajor(item) {
      const url = `api/Major/SchoolCode(${item.SchoolCode}),MajorId(${item.MajorId})`;

      try {
        await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async saveMajorData() {
      // 1為新增 2為編輯
      let obj = {};

      obj = {
        SchoolCode: this.modalSchoolCode,
        SchoolName: this.modalSchoolName,
        MajorId: this.modalMajorId,
        MajorName: this.modalMajorName,
        MajorYear: this.modalMajorYear,
        IsActive: this.modalIsActive,
        SchoolId: this.modalSchoolId,
      };

      try {
        const url = "api/Major";
        this.modalType == 1
          ? await axios.post(url, obj)
          : await axios.put(url, obj);
        this.getData();
        this.$bvToast.toast(`${this.editKind == 1 ? "新增" : "編輯"}成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(
          `${
            !Boolean(e.response)
              ? e
              : e.response.data.includes("Duplicate")
              ? "目前此系組別已存在，請重新選擇"
              : e.response.data
          }`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          }
        );
      }
    },
    openModal(key, item) {
      this.modalType = key;

      if (+key == 1) {
        this.modalSchoolCode = "";
        this.modalSchoolName = "";
        this.modalSchoolId = "";
        this.modalMajorIdChinese = "";
        this.modalMajorId = "";
        this.modalMajorName = "";
        this.modalMajorYear = "";
        this.modalIsActive = false;
      } else {
        this.modalSchoolCode = item.SchoolCode;
        this.modalSchoolName = item.SchoolName;
        this.modalSchoolId = item.SchoolId;
        this.modalMajorIdChinese = item.majorIdChinese;
        this.modalMajorId = item.MajorId;
        this.modalMajorName = item.MajorName;
        this.modalMajorYear = item.MajorYear;
        this.modalIsActive = item.IsActive;
      }
      this.$bvModal.show("editModal");
    },
    async saveMajorIsActiveData(item) {
      const obj = {
        SchoolCode: item.SchoolCode,
        SchoolName: item.SchoolName,
        MajorId: item.MajorId,
        MajorName: item.MajorName,
        MajorYear: item.MajorYear,
        IsActive: item.IsActive,
        SchoolId: item.SchoolId,
      };
      try {
        const url = "api/Major";
        await axios.put(url, obj);
        this.getData();
        this.$bvToast.toast(`啟用狀態編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast(
          `${
            !Boolean(e.response)
              ? e
              : e.response.data.includes("Duplicate")
              ? "目前此系組別已存在，請重新選擇"
              : e.response.data
          }`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          }
        );
      }
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    clearSearch() {
      this.search = false;
      this.searchSchool = null;
      this.searchMajor = "";
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      let orderBy = this.orderBy;
      orderBy.includes("SchoolCode") ? "" : orderBy.unshift("SchoolCode");
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (this.searchSchool) {
        qs = this.$equalFilter("SchoolCode", qs, this.searchSchool);
        bQs = true;
      }
      if (this.searchMajor) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("MajorName", qs, this.searchMajor);
        } else {
          qs = this.$equalSubstringofFilter("MajorName", qs, this.searchMajor);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(`api/Major${qs}`);
        Items = Items.map((s) => {
          s.majorIdChinese = this.majorObject.find((k) => +k.code == +s.MajorId)
            ? this.majorObject.find((k) => +k.code == +s.MajorId).text
            : "";
          return s;
        });
        let schoolObj = JSON.parse(JSON.stringify(this.schoolObject));
        schoolObj = schoolObj.map((s, i) => {
          s.index = i + 1;
          s.array = Items.filter((k) => +k.SchoolCode == s.value);
          return s;
        });
        this.items = JSON.parse(JSON.stringify(schoolObj));

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const { Items } = await window.axios.get("api/School");
      this.schoolObject = Items.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
        id: s.SchoolId,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
      this.schoolObjectForModal = Items.map((s) => ({
        value: s.SchoolName,
        text: s.SchoolName,
        id: s.SchoolId,
        code: s.SchoolCode,
      }));
    },
    async getMajor() {
      const arr = await window.axios.get("SysCode/Get?category=major");
      this.majorObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
        code: s.Code,
      }));
    },
  },
  mounted() {
    this.getMajor();
    this.getAllSchool();
    this.getData();
  },
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    modalSchoolName(v) {
      const obj = this.schoolObject.find((s) => s.text == v);
      this.modalSchoolCode = obj.value;
      this.modalSchoolId = obj.id;
    },
    modalMajorIdChinese(v) {
      const obj = this.majorObject.find((s) => s.text == v);
      this.modalMajorId = obj.code;
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 120px 140px 170px 180px repeat(5, 170px);
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #dee2e5;
  border-bottom: 0px;
  margin-right: 4px;
  color: #0757a7;
  > div {
    border-right: 1px solid #dee2e5;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    height: 36px;
    line-height: 36px;
  }
  > div:last-child {
    border-right: none;
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer {
  display: grid;
  grid-template-columns: 40px 120px 50px 120px repeat(11, 120px) 176px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #dee2e5;
  border-right: 1px solid #dee2e5;

  color: #212529;
  > div {
    border-right: 1px solid #dee2e5;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer {
  grid-template-columns: 1390px 130px 200px;
  border: 1px solid #dee2e5;
  color: #212529;

  > div {
    border-right: 1px solid #dee2e5;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
.search-block {
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 2px 2px 0 0;
  width: 99.3%;
  margin: 0 auto 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
}
td {
  border: 1px solid #dee2e5;
  text-align: center;
}
.table-content {
  padding: 3px;
}
.input-group-text {
  width: 122px;
  background: #0379fd;
  color: #fff;
}
.edit-content {
  text-align: end;
}
.price-content {
  text-align: right;
  padding-right: 6.5px;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
</style>
