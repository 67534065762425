<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        衛生單位管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="類別">
          <b-select
            :options="healthKindObject"
            v-model="searchHealthKind"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="單位名稱">
          <treeselect
            style="width: 400px;color:#333;"
            v-model="searchUnit"
            :options="unitOptions"
            placeholder="選擇衛生單位代碼"
            noResultsText="查無此衛生單位代碼.."
          />
        </b-input-group>
        <b-input-group prepend="單位代碼">
          <b-input v-model="searchCode" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="啟用狀態">
          <b-select
            :options="isActiveObject"
            v-model="searchIsActive"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增單位</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div
        style="padding: 2.5px 0 0 30px;-webkit-box-orient: horizontal;"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        <b-button
          variant="success"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >編輯</b-button
        >
        <b-check
          switch
          v-model="item.viewDetail"
          @change="openCenterDetail(item, i)"
        >
          管理
        </b-check>
      </div>
      <div
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        <b-check
          switch
          v-model="item.IsActive"
          @change="saveIsActiveData(item)"
        >
          啟用
        </b-check>
      </div>
      <div
        :title="item.categoryKind"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ item.categoryKind || "" }}
      </div>
      <div
        :title="item.Code"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ item.Code || "" }}
      </div>
      <div
        :title="item.Name"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ item.Name || "" }}
      </div>
      <div
        :title="item.ShortName"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ item.ShortName || "" }}
      </div>
      <div
        :title="item.levelKind"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ item.levelKind || "" }}
      </div>
      <div
        :title="item.countyKind"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ item.countyKind || "" }}
      </div>
      <div
        :title="item.CreateTime"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ $twDate2(item.CreateTime) || "" }}
      </div>
      <div
        :title="item.UpdateTime"
        :style="
          i != 0 && items[i - 1].viewDetail
            ? 'border-top: 1px solid #cccccc;'
            : ''
        "
      >
        {{ $twDate2(item.UpdateTime) || "" }}
      </div>
      <div class="my-light modal-grid-search-2" v-if="item.viewDetail">
        <header>
          <div></div>
          <div>單位代碼</div>
          <b-input
            v-model.trim="item.searchDetailCode"
            @keydown.enter="searchCenterDetail(item, i)"
            size="sm"
            placeholder="搜尋單位代碼"
          ></b-input>
          <div></div>
          <div>單位名稱</div>
          <b-input
            v-model.trim="item.searchDetailName"
            @keydown.enter="searchCenterDetail(item, i)"
            size="sm"
            placeholder="搜尋單位名稱"
          ></b-input>
        </header>
      </div>
      <div class="view-details my-light" v-if="item.viewDetail">
        <!-- <header>
          <div></div>
          <div>單位代碼</div>
          <div>單位名稱</div>
        </header> -->
        <label
          v-for="(row, rowId) in item.detailRowsShow"
          :key="`$detailRowsShow${rowId}`"
        >
          <b-check
            v-model="row.isActive"
            class="ml-2"
            size="lg"
            @change="updateCheckBox(row, item, i)"
          ></b-check>
          <div>{{ row.Code }}</div>
          <div>{{ row.Name }}</div>
        </label>
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '修改'}衛生單位資料`"
        size="lg"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="單位代碼">
            <b-input
              v-model="modalItem.Code"
              style="padding-right:28px"
              :disabled="modalType == 2"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="單位名稱">
            <b-input
              v-model="modalItem.Name"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="單位簡稱">
            <b-input
              v-model="modalItem.ShortName"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="縣市別">
            <b-select
              :options="countryOptions"
              v-model="modalItem.County"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="啟用狀態">
            <b-select
              :options="[
                { value: true, text: '啟用' },
                { value: false, text: '不啟用' },
              ]"
              v-model="modalItem.IsActive"
            ></b-select>
          </b-input-group>
          <div></div>
          <b-input-group prepend="類別">
            <b-select
              :options="healthKindObject.slice(1)"
              v-model="modalItem.Category"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="醫院層級">
            <b-select
              :options="levelOptions"
              v-model="modalItem.Level"
              :disabled="modalItem.Category == '01'"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="地址" class="one-row">
            <b-input
              v-model="modalItem.Address"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="電話">
            <b-input
              v-model="modalItem.Phone"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="傳真">
            <b-input
              v-model="modalItem.Fax"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="E-MAIL" class="one-row">
            <b-input
              v-model="modalItem.Email"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { isActiveObject, healthKindObject } from "@/constant.js";

const headersOne = [
  { name: "操作", key: "", sortDesc: null },
  { name: "啟用狀態", key: "IsActive", sortDesc: null },
  { name: "類別", key: "Category", sortDesc: null },
  { name: "單位代碼", key: "Code", sortDesc: null },
  { name: "單位名稱", key: "Name", sortDesc: null },
  { name: "簡稱", key: "ShortName", sortDesc: null },
  { name: "單位層級", key: "Level", sortDesc: null },
  { name: "縣市別", key: "County", sortDesc: null },
  { name: "建立日期", key: "CreateTime", sortDesc: null },
  { name: "更新日期", key: "UpdateTime", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersOne,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {},
      modalType: 1,

      //search
      searchHealthKind: null,
      searchUnit: "",
      searchCode: "",
      searchIsActive: null,

      healthKindObject,
      unitOptions: [],
      isActiveObject,

      levelOptions: [],
      countryOptions: [],
    };
  },
  components: {
    Treeselect,
  },
  computed: {},

  methods: {
    async openCenterDetail(item, key) {
      const url = `HealthCenter/GetRelations?code=${item.Code}`;
      try {
        const { Include, List } = await window.axios.get(url);
        const arr = List.map((s) => {
          s.parantCode = item.Code;
          s.isActive = Include.find((k) => k == s.Code) ? true : false;
          return s;
        });
        this.items[key].detailRows = JSON.parse(JSON.stringify(arr));
        this.items[key].detailRowsShow = JSON.parse(JSON.stringify(arr));
        this.items[key].searchDetailCode = "";
        this.items[key].searchDetailName = "";
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    searchCenterDetail(item, key) {
      let arr = [];
      let allData = JSON.parse(JSON.stringify(item.detailRows));
      if (Boolean(item.searchDetailName)) {
        arr = allData.filter((s) => s.Name.includes(item.searchDetailName));
      }
      if (Boolean(item.searchDetailCode)) {
        arr = allData.filter((s) => s.Code.includes(item.searchDetailCode));
      }
      if (!Boolean(item.searchDetailName) && !Boolean(item.searchDetailCode)) {
        arr = JSON.parse(JSON.stringify(item.detailRows));
      }
      this.items[key].detailRowsShow = JSON.parse(JSON.stringify(arr));
    },
    async updateCheckBox(item, originalitem, key) {
      const obj = {
        Code: item.parantCode,
        RelationCode: item.Code,
      };
      const childIndexOf = originalitem.detailRows.findIndex(
        (s) => s.Code == item.Code
      );
      const urlAdd = "HealthCenter/AddRelation";
      const urlRemove = "HealthCenter/RemoveRelation";

      try {
        item.isActive
          ? await window.axios.put(urlAdd, obj)
          : await window.axios.put(urlRemove, obj);
        this.items[key].detailRows[childIndexOf].isActive = item.isActive;

        this.$bvToast.toast(
          `${item.isActive ? "新增" : "移除"}相關權責單位成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    clearSearch() {
      this.searchHealthKind = null;
      this.searchUnit = null;
      this.searchCode = "";
      this.searchIsActive = null;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      this.modalType = type;
      if (type == 1) {
        this.modalItem = {
          Code: "",
          Category: "",
          Name: "",
          ShortName: "",
          Phone: "",
          Address: "",
          Fax: "",
          Email: "",
          CreateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          Creater: "",
          UpdateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          Update: "",
          IsActive: true,
          Level: "",
          County: "",
        };
      } else {
        this.modalItem = { ...item };
        this.modalItem.UpdateTime = this.$moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }
      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/HealthCenterController`;
      const obj = {
        ...this.modalItem,
      };
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `衛生單位${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async saveIsActiveData(item) {
      const url = `api/HealthCenterController`;
      const obj = {
        ...item,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (this.searchHealthKind) {
        qs = this.$equalFilter("Category", qs, this.searchHealthKind);
        bQs = true;
      }
      if (this.searchUnit) {
        if (bQs) {
          qs = this.$appendEqualFilter("Name", qs, this.searchUnit);
        } else {
          qs = this.$equalFilter("Name", qs, this.searchUnit);
        }
        bQs = true;
      }
      if (this.searchCode) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Code", qs, this.searchCode);
        } else {
          qs = this.$equalSubstringofFilter("Code", qs, this.searchCode);
        }
        bQs = true;
      }

      if (typeof this.searchIsActive == "boolean") {
        if (bQs) {
          qs = this.$appendEqualFilter2("IsActive", qs, this.searchIsActive);
        } else {
          qs = this.$equalFilter2("IsActive", qs, this.searchIsActive);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(
          `api/HealthCenterController${qs}`
        );

        Items = Items.map((s) => {
          s.viewDetail = false;
          s.detailRows = [];
          s.detailRowsShow = [];
          s.levelKind = this.levelOptions.find((k) => k.value == s.Level)
            ? this.levelOptions.find((k) => k.value == s.Level).text
            : "";
          s.categoryKind = this.healthKindObject.find(
            (k) => k.value == s.Category
          )
            ? this.healthKindObject.find((k) => k.value == s.Category).text
            : "";
          s.countyKind = this.countryOptions.find((k) => k.value == s.County)
            ? this.countryOptions.find((k) => k.value == s.County).text
            : "";
          s.searchDetailCode = "";
          s.searchDetailName = "";
          return s;
        });
        this.items = [...Items];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getSearchOptions() {
      const arr = await window.axios.get("SysCode/Get?category=hc_level");
      this.levelOptions = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));

      const arr2 = await window.axios.get("SysCode/Get?category=country");
      this.countryOptions = arr2.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));

      const { Items } = await window.axios.get(`api/HealthCenterController`);
      let arr3 = Items.map((s) => ({
        id: s.Name,
        label: s.Name,
      }));
      arr3.unshift({ id: "", label: "全部" });
      const uniqueArr3 = this.$dedup(arr3);
      this.unitOptions = [...uniqueArr3];
    },
  },
  async mounted() {
    await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    "modalItem.Category"(v) {
      if (v == "01") {
        this.modalItem.Level = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 180px 140px 120px 170px 300px 220px 130px 130px 130px 135px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 38px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(9, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.view-details {
  grid-column: 1 / -1;
  display: grid;
  background: black;
  color: white;
  min-height: 200px;
  font-size: 16px;
  margin: 8px;
  border-radius: 0px;
  text-align: left;
  line-height: 30px;
  margin-top: 0;
  overflow: auto !important;
  label {
    all: unset;
  }
  header,
  main,
  label {
    display: grid;
    border: 1px solid white;
    border-top: none;
    grid-template-columns: 90px 300px 1fr;
    background: #4e5357;
  }
  label,
  main {
    border: none;
    border-bottom: 1px solid white;
    background: #101111;
    cursor: pointer;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  > div.one-row {
    grid-column: 1/-1;
  }
}
.modal-grid-search-2 {
  grid-column: 1/-1;
  background: black;
  color: white;
  font-size: 16px;
  margin: 8px;
  margin-bottom: 0;
  border-radius: 0px;
  text-align: left;
  line-height: 30px;
  overflow: hidden;

  header {
    display: grid;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
    grid-template-columns: 90px 100px 185px 15px 82px 185px;
    background: #4e5357;
  }
}
</style>
