<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
        <component
          v-if="tabName === item.title"
          :is="item.comps"
          :tabName="item.comps"
        />
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import SchoolSetting from "./SchoolSetting";
import MajorSetting from "./MajorSetting";
import CountrySetting from "./CountrySetting";
import HealthCenterSetting from "./HealthCenterSetting";
import PostCodeSetting from "./PostCodeSetting";
import SchoolCodeSetting from "./SchoolCodeSetting";
import LocalMedicalSetting from "./LocalMedicalSetting";
import NurseMedicalSetting from "./NurseMedicalSetting";
import TrainingMedicalSetting from "./TrainingMedicalSetting";
import SystemCodeSetting from "./SystemCodeSetting";
import CultivationPlanSetting from "./CultivationPlanSetting";

export default {
  name: "totalSpendRuralTabsVue",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「基本資料」",
      titles: [
        {
          title: "學校資料管理",
          comps: "SchoolSetting",
          isActive: false,
          functionId: "5000C",
        },
        {
          title: "學校系別管理",
          comps: "MajorSetting",
          isActive: false,
          functionId: "5000D",
        },
        {
          title: "籍屬身分管理",
          comps: "CountrySetting",
          isActive: false,
          functionId: "5000E",
        },
        {
          title: "衛生單位管理",
          comps: "HealthCenterSetting",
          isActive: false,
          functionId: "5000F",
        },
        {
          title: "郵遞編號管理",
          comps: "PostCodeSetting",
          isActive: false,
          functionId: "5000H",
        },
        {
          title: "招生學校代碼管理",
          comps: "SchoolCodeSetting",
          isActive: false,
          functionId: "5000I",
        },
        {
          title: "在地養成服務單位及學校設置",
          comps: "LocalMedicalSetting",
          isActive: false,
          functionId: "5000J",
        },
        {
          title: "偏護菁英-分發單位及學校設置",
          comps: "NurseMedicalSetting",
          isActive: false,
          functionId: "5000K",
        },
        {
          title: "培訓單位設置",
          comps: "TrainingMedicalSetting",
          isActive: false,
          functionId: "5000L",
        },
        {
          title: "系統代碼資料維護",
          comps: "SystemCodeSetting",
          isActive: false,
          functionId: "5000Z",
        },
        {
          title: "重點科別培育計畫服務單位及學校設置",
          comps: "CultivationPlanSetting",
          isActive: false,
          functionId: "5000M",
        },
      ],
    };
  },
  computed: {
    nowTab() {
      return store.nowTab;
    },
  },
  components: {
    VueTabs,
    VTab,
    SchoolSetting,
    MajorSetting,
    CountrySetting,
    HealthCenterSetting,
    PostCodeSetting,
    SchoolCodeSetting,
    LocalMedicalSetting,
    NurseMedicalSetting,
    TrainingMedicalSetting,
    SystemCodeSetting,
    CultivationPlanSetting
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.setUserRight();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
