<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        系統代碼資料管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="類別">
          <b-select
            :options="categoryOptions"
            v-model="searchCategory"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="代碼名稱">
          <b-input v-model="searchCodeName" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="代碼">
          <b-input v-model="searchCode" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增代碼</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding: 2.5px 0 0 30px;-webkit-box-orient: horizontal;">
        <b-button
          variant="success"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >編輯</b-button
        >
      </div>
      <div>
        <b-check
          switch
          v-model="item.IsActive"
          @change="saveIsActiveData(item)"
        >
          啟用
        </b-check>
      </div>
      <div :title="item.Category">
        {{ item.Category || "" }}
      </div>
      <div :title="item.Display">
        {{ item.Display || "" }}
      </div>
      <div :title="item.Code">
        {{ item.Code || "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '修改'}代碼資料`"
        size="md"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="類別">
            <b-select
              :options="categoryOptions"
              v-model="modalItem.Category"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="代碼名稱" class="mt-2">
            <b-input
              v-model="modalItem.Display"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>

          <b-input-group prepend="代碼" class="mt-2">
            <b-input
              v-model="modalItem.Code"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>

          <b-input-group prepend="啟用狀態" class="mt-2">
            <b-select
              :options="[
                { value: true, text: '啟用' },
                { value: false, text: '不啟用' },
              ]"
              v-model="modalItem.IsActive"
            ></b-select>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "啟用狀態", key: "IsActive", sortDesc: null },
  { name: "類別", key: "Category", sortDesc: null },
  { name: "代碼名稱", key: "Display", sortDesc: null },
  { name: "代碼", key: "Code", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {},
      modalType: 1,

      //search
      searchCategory: null,
      searchCodeName: "",
      searchCode: "",

      //searchOptions or ModalOptions
      categoryOptions: [],
    };
  },
  components: {
    Treeselect,
  },
  computed: {},

  methods: {
    clearSearch() {
      this.searchCategory = null;
      this.searchCodeName = "";
      this.searchCode = "";
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      this.modalType = type;
      if (type == 1) {
        this.modalItem = {
          Category: "",
          Code: "",
          Display: "",
          IsActive: true,
        };
      } else {
        this.modalItem = { ...item };
      }
      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/SysCode`;
      const obj = {
        ...this.modalItem,
      };
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `系統代碼${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async saveIsActiveData(item) {
      const url = `api/SysCode`;
      const obj = {
        ...item,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (this.searchCategory) {
        qs = this.$equalFilter("Category", qs, this.searchCategory);
        bQs = true;
      }
      if (this.searchCodeName) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "Display",
            qs,
            this.searchCodeName
          );
        } else {
          qs = this.$equalSubstringofFilter("Display", qs, this.searchCodeName);
        }
        bQs = true;
      }
      if (this.searchCode) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Code", qs, this.searchCode);
        } else {
          qs = this.$equalSubstringofFilter("Code", qs, this.searchCode);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(`api/SysCode${qs}`);

        this.items = [...Items];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getSearchOptions() {
      const { Items } = await window.axios.get("api/SysCode");
      const arr = Items.map((s) => s.Category);
      let uniqueArr = [...new Set(arr)];
      uniqueArr.unshift({ value: null, text: "全部" });
      this.categoryOptions = JSON.parse(JSON.stringify(uniqueArr));
    },
  },
  async mounted() {
    await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 120px 200px 300px 300px 440px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 38px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
</style>
