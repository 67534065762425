<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        學校資料管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="學校代碼">
          <b-input v-model="searchCode" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="學校名稱">
          <b-input
            list="my-list-id"
            v-model="searchName"
            @keydown.enter="getData"
          ></b-input>
          <datalist id="my-list-id">
            <option
              v-for="(item, i) in schoolNameOptions"
              :key="`schoolNameOptions${i}`"
              >{{ item }}</option
            >
          </datalist>
        </b-input-group>

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增學校</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div :title="item.servicenow" style="padding:2px 0">
        <b-button variant="success" size="sm" @click="openModal(2, item)"
          >編輯</b-button
        >
      </div>
      <div>
        <b-check
          switch
          v-model="item.IsActive"
          @change="saveIsActiveData(item)"
        >
          啟用
        </b-check>
      </div>
      <div :title="item.SchoolCode">
        {{ item.SchoolCode || "" }}
      </div>
      <div :title="item.SchoolName">
        {{ item.SchoolName || "" }}
      </div>
      <div :title="item.SchoolId">{{ item.SchoolId || "" }}</div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '修改'}學校資料`"
        size="md"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="學校名稱">
            <b-input
              v-model="schoolName"
              style="padding-right:28px"
              placeholder="輸入學校名稱"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="學校代碼" class="mt-2">
            <b-input
              v-model="schoolCode"
              style="padding-right:28px"
              :disabled="modalType == 2"
              maxlength="4"
              @keypress="isNumber($event)"
              placeholder="輸入學校代碼(上限四個字元)"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="招生編號" class="mt-2">
            <b-input
              v-model="schoolId"
              style="padding-right:28px"
              maxlength="1"
              placeholder="輸入招生編號(上限一個字元)"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="啟用狀態" class="mt-2">
            <b-select
              :options="[
                { value: true, text: '啟用' },
                { value: false, text: '不啟用' },
              ]"
              v-model="itemIsActive"
            ></b-select>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";

const headersOne = [
  { name: "操作", key: "", sortDesc: null },
  { name: "啟用狀態", key: "IsActive", sortDesc: null },
  { name: "學校代碼", key: "SchoolCode", sortDesc: null },
  { name: "學校名稱", key: "SchoolName", sortDesc: null },
  { name: "招生編號", key: "SchoolId", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      headersOne,

      items: [],

      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      schoolName: "",
      schoolCode: "",
      schoolId: "",
      itemIsActive: false,
      modalType: 1,

      //search
      searchName: "",
      searchCode: "",

      //searchOptions or ModalOptions
      schoolNameOptions: [],
      checkOption: [],
    };
  },
  components: {},
  computed: {},

  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clearSearch() {
      this.searchCode = null;
      this.searchName = "";
      this.getData();
    },
    async saveIsActiveData(item) {
      const url = `api/School`;
      const obj = {
        ...item,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      this.modalType = type;
      if (type == 1) {
        this.schoolCode = "";
        this.schoolName = "";
        this.schoolId = "";
        this.itemIsActive = true;
      } else {
        this.schoolCode = item.SchoolCode;
        this.schoolName = item.SchoolName;
        this.schoolId = item.SchoolId;
        this.itemIsActive = item.IsActive;
      }
      this.$bvModal.show("editModal");
    },
    checkIsDuplicate(obj) {
      let arr = JSON.parse(JSON.stringify(this.checkOption));
      const indexOf = this.checkOption.findIndex(
        (s) => s.SchoolCode == obj.SchoolCode
      );
      //編輯才要把原本的剃除，新增不用
      if (this.modalType == 2) {
        arr.splice(indexOf, 1);
      }
      const isSchoolCodeDuplicate = arr.find(
        (s) => s.SchoolCode == obj.SchoolCode
      )
        ? true
        : false;
      const isSchoolIdDuplicate = arr.find((s) => s.SchoolId == obj.SchoolId)
        ? true
        : false;
      if (isSchoolCodeDuplicate || isSchoolIdDuplicate) {
        return `Error${isSchoolCodeDuplicate ? "，此學校代碼已存在" : ""}${
          isSchoolIdDuplicate ? "，此招生編號已存在" : ""
        }，請重新輸入`;
      } else {
        return "Correct";
      }
    },
    async saveModal() {
      const url = `api/School`;
      const obj = {
        SchoolCode: this.schoolCode,
        SchoolName: this.schoolName,
        SchoolId: this.schoolId,
        IsActive: this.itemIsActive,
      };

      const resMessage = this.checkIsDuplicate(obj);
      if (resMessage.includes("Error")) {
        this.$bvToast.toast(`${resMessage}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `學校${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.getSearchOptions();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;
      if (this.searchCode) {
        qs = this.$equalSubstringofFilter("SchoolCode", qs, this.searchCode);
        bQs = true;
      }
      if (this.searchName) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("SchoolName", qs, this.searchName);
        } else {
          qs = this.$equalSubstringofFilter("SchoolName", qs, this.searchName);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(`api/School${qs}`);
        this.items = { ...Items };
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getSearchOptions() {
      const { Items } = await window.axios.get("api/School");
      const arr = Items.map((s) => s.SchoolName);
      const arr2 = Items.map((s) => s);

      this.schoolNameOptions = this.$dedup(arr);
      this.checkOption = this.$dedup(arr2);
    },
  },
  async mounted() {
    await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 120px 120px 360px 400px 360px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: max-content 370px repeat(3, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  padding-right: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
</style>
